import React from "react";

export default function Title({ top, bottom, color = `black`, name }) {
  return (
    <div name={name}>
      <span className={`block font-blod text-${color} text-3xl`}>{top}</span>
      <span
        className={`block font-black  text-${color} text-3xl  leading-none`}
      >
        {bottom}
      </span>
    </div>
  );
}
