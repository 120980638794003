import React from "react";

export default function Container({ children, padding }) {
  return (
    <div
      className={`mx-auto w-full max-w-4xl ${padding ? `px-4 py-8` : ``}   ${
        padding ? `md:p-8` : ``
      }`}
    >
      {children}
    </div>
  );
}
